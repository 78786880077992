import { useContext, useEffect, useCallback, useRef } from 'react';
import DataContext from './DataContext';
import AnimationContext from './AnimationContext';
import LocationPicker from './LocationPicker';
import '../css/Location.css';
import { getCSSVariable } from '../utils/Helpers';
import { actions } from '../App';

function Location({ state, dispatch }) {
    const stateRef = useRef(state);
    const { locationPick, setLocationPick } = useContext(DataContext);
    const animation = useContext(AnimationContext);
    const locationLabelHeight = getCSSVariable('locationLabelHeight');
    
    // fetches and sets location 
    const fetchLocation = useCallback( (location) => {
        // Fetch location data from the browser
        console.log('Location: fetchLocation');
        setLocationPick(location);
    }, [setLocationPick]);

    // transitions to PICKER
    const handleStartClick = useCallback(() => {
        if (state !== 'SPLASH') return
        console.log('Location: handleStart');
        dispatch({ type: actions.START_PICKER });
        animation.splashToPicker();
    }, [state, dispatch, animation]);
    
    // transitions to LOADER and calls fetchLocation
    const handleLocationSelect = useCallback((location) => {
        if (state !== 'PICKER') return
        console.log('Location: handleLocatioSelect, location:');
        console.log('Name:', location.name);
        console.log('Latitude:', location.lat);
        console.log('Longitude:', location.lng);
        dispatch({ type: actions.START_LOADER });
        animation.pickerToLoader(locationLabelHeight);
        fetchLocation(location);
    }, [state, dispatch, animation, locationLabelHeight, fetchLocation]);

    // transitions back to PICKER
    const handleClose = () => {
        if (state !== 'CHART') return
        console.log('Location: handleClose');
        // set empty location to reset wave canvas
        const location = {};
        setLocationPick(location);
        // animateFromChart();
        dispatch({ type: actions.BACK_TO_PICKER });
        animation.chartToPicker();
    };

    useEffect (() => {
        stateRef.current = state;
    }, [state]);

    // listens for Enter key press
    // fires start click
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                if (stateRef.current  === 'SPLASH') {
                    handleStartClick();
                } 
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleStartClick]);
 
    return (
            <div id="locationContainer">

                <div id="location">
                    <div id="siteTitle">
                        <div id="wave"></div>
                        <h1>The Tide Guide</h1>
                        <h2>See the times and heights<br />of the tide near you</h2>
                    </div>
                    <div id="btn-start" onClick={handleStartClick} > </div>
  
                    <LocationPicker 
                        handleLocationSelect={handleLocationSelect}
                    />               

                    <div id="locationLabel">
                        <div id="locationLabelText">
                            <h2>The tide near</h2>
                            <h1>{locationPick.name}</h1>
                        </div>
                        <div id="btn-close" onClick={handleClose}></div>
                    </div>
                </div>

                <div id="locationRecommendations">
                    <div className='btn-locationRecommendation' 
                        onClick={() => handleLocationSelect({ name:'Bolinas', lat:37.9093702, lng:-122.6863733 })}
                     >Bolinas</div>
                     <div className='btn-locationRecommendation'
                        onClick={() => handleLocationSelect({name: 'Seattle', lat: 47.6061389, lng: -122.3328481})}
                    >Seattle</div>
                    <div className='btn-locationRecommendation last'
                        onClick={() => handleLocationSelect({name: 'Barcelona', lat: 41.3873974, lng: 2.168568})}
                    >Barcelona</div>
                </div>

            </div>
    );
}

export default Location;
        

