// helpers.js

export function getCSSVariable(variableName) {
    const root = document.documentElement;
    const computedStyle = getComputedStyle(root);
    const variableValue = computedStyle.getPropertyValue(`--${variableName}`).trim();
    return variableValue;
}

export const formatDate = (date) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
        return 'Today';
    } else if (date.toDateString() === tomorrow.toDateString()) {
        return 'Tomorrow';
    } else if (date.toDateString() === yesterday.toDateString()) {
        return 'Yesterday';
    } else {
        const options = { 
        month: 'long', 
        day: 'numeric' 
        };
    
        const day = date.getDate();
        const suffix = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
        const ordinal = ([1, 2, 3].includes(day%10) && ![11, 12, 13].includes(day%100)) ? suffix[day%10] : suffix[0];
    
        return `${date.toLocaleDateString('en-US', options)}${ordinal}`;
    }
};

export const formatTime = (date) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    let timeString = date.toLocaleTimeString('en-US', options);
    // Convert AM/PM to lowercase
    return timeString.replace(/AM|PM/g, match => match.toLowerCase());
};

//takes target date and hour, returns UTC ISO format at time passed as hour
export const getISOTime = (targDate, hour) => { 
    const d = new Date(targDate);
    d.setHours(hour,0,0,0);
    const isoTime = d.toISOString();
    return (isoTime)
};

export function getScrollPositionForTime(time, data, totalScrollWidth) {
    const startTime = new Date(data[0].time);
    const totalTimeRange = new Date(data[data.length - 1].time) - startTime;
    return (time - startTime) / totalTimeRange * totalScrollWidth;
}

export function getTimeForScrollPosition(scrollX, data, totalScrollWidth, offset = 0) {
    const startTime = new Date(data[0].time);
    const endTime = new Date(data[data.length - 1].time);
    const totalTimeRange = endTime - startTime;
    const time = new Date(startTime.getTime() + (scrollX + offset) / totalScrollWidth * totalTimeRange);
    return time;
}

export const getHeightForScrollPosition = (scrollX, data, totalScrollWidth, offsetAmount) => {
    const scrollPosition = scrollX + offsetAmount;
    const proportion = scrollPosition / totalScrollWidth;
    const totalTimeRange = new Date(data[data.length - 1].time) - new Date(data[0].time);
    const currentTime = new Date(data[0].time.getTime() + proportion * totalTimeRange);

    let previousData = null;
    let nextData = null;

    for (let i = 0; i < data.length - 1; i++) {
        if (new Date(data[i].time) <= currentTime && new Date(data[i + 1].time) > currentTime) {
            previousData = data[i];
            nextData = data[i + 1];
            break;
        }
    }

    if (previousData && nextData) {
        const timeRange = new Date(nextData.time) - new Date(previousData.time);
        const timeProportion = (currentTime - new Date(previousData.time)) / timeRange;
        const heightRange = nextData.height - previousData.height;
        const newHeight = previousData.height + timeProportion * heightRange;
        return newHeight;
    }

    return null;
};