import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../css/LocationPicker.css';


const LocationPicker = ({ handleLocationSelect }) => {
  const [inputValue, setInputValue] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [selectedPrediction, setSelectedPrediction] = useState(-1);
  const [isMouseInside, setIsMouseInside] = useState(false);
  const service = useRef(null);
  const placesService = useRef(null);

  // fires the google places API load on mount
  useEffect(() => {
    loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDIR1P4WP-ydz6ybIxqKTQQwQQI_0yxRog&libraries=places", () => {
      service.current = new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(document.createElement('div'));
    });
  }, []);

  // loads the google places API script
  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {  //IE
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {  //Others
      script.onload = function () {
        callback();
      };
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  // when user types in the input
  // get predictions from google places API and updates state
  const handleInputChange = (event) => {
    
    setInputValue(event.target.value);
    setSelectedPrediction(-1);
    
    const locationRecommendations = document.querySelector("#locationRecommendations");
    
    if (event.target.value.length > 0) {
      locationRecommendations.style.display = "none";

      service.current.getPlacePredictions({ 
        input: event.target.value,
        types: ['(regions)'] 
      }, (predictions, status) => {
        setPredictions(predictions || []);
      });
    } else {
      locationRecommendations.style.display = "block";
      setPredictions([]);
    }
  };

  // when user clicks on a prediction
  // clear the input text and results
  const handleClick = useCallback((prediction) => {

    const request = {
      placeId: prediction.place_id,
      fields: ['name', 'geometry']
    };
  
    placesService.current.getDetails(request, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const name = place.name;
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        handleLocationSelect({ name, lat, lng });
        setInputValue("");
        setPredictions([]);
        setSelectedPrediction(-1);
      }
      else{
        console.log('Place not found');
      }
    });
  }, [handleLocationSelect]);

  // handle key press events
  // listens for arrow up and down
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "ArrowUp") {
        setSelectedPrediction((prevSelected) => Math.max(0, prevSelected - 1));
      } else if (event.key === "ArrowDown") {
        setSelectedPrediction((prevSelected) => Math.min(predictions.length - 1, prevSelected + 1));
      }
      if (event.key === "Enter" && selectedPrediction !== -1) {
        handleClick(predictions[selectedPrediction]);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [predictions.length, handleClick, selectedPrediction, predictions]);

  return (
    <>
    <div id="locationPicker">
      <input 
        value={inputValue} 
        onChange={handleInputChange} 
        type="text" 
        placeholder="Find a location" 
      
      />
      <div id="locationSuggestions"
        onMouseEnter={() => setIsMouseInside(true)} 
        onMouseLeave={() => {setIsMouseInside(false); setSelectedPrediction(-1);}} 
      >
        {predictions.map((prediction, index) => {
          let className = "btn-locationSuggestion";
          if (index === 0) className += " firstSuggestion";
          if (index === predictions.length - 1) className += " lastSuggestion";
          if (index === selectedPrediction && !isMouseInside) className += " selected"; // Add this line

          // Assuming `inputValue` is the variable holding the user's input
          const inputValueLength = inputValue.length;
          const match = prediction.description.slice(0, inputValueLength);
          const rest = prediction.description.slice(inputValueLength);

          return (
            <div 
              className={className} 
              key={index} 
              onClick={() => handleClick(prediction)}
              onMouseEnter={() => setSelectedPrediction(-1)}
            >
              <span className="inputText">{match}</span>{rest}
            </div>
          );
        })}
      </div>
    </div>
    </>
  );
};

export default LocationPicker;