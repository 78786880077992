import React, { useEffect, useContext, useRef, useState, useCallback } from 'react';
import DataContext from './DataContext';
import AnimationContext from './AnimationContext';
import WaveCanvas from './WaveCanvas';
import ScrollController from './ScrollController';
import '../css/TideGraph.css';
//import { getData } from './TestData';
import { getCanvasMeasurements, getIndices, getTimesAndHeights } from '../utils/Calculators';
import { getCSSVariable, getISOTime } from '../utils/Helpers';
import { actions } from '../App';

function TideGraph({ state, dispatch }) {
    const dispatchRef = useRef(dispatch);
    const { locationPick } = useContext(DataContext);
    const animation = useContext(AnimationContext);
    const animationRef = useRef(animation);
    //const scrollContainerRef = useRef(null);

    const daysContainerRef = useRef(null);
    const tickMarkContainerRef = useRef(null);
    const waveCanvasRef = useRef(null);
    const arrowRef = useRef(null);
    
    const daysPerFetch = getCSSVariable('daysPerFetch');
    const [data, setData] = useState([]);
    const dataRef = useRef(data);
    const [isRendered, setIsRendered] = useState(false);
   
    const [canvasMeasurements, setCanvasMeasurements] = useState({});
    const canvasMeasurementsRef = useRef(canvasMeasurements);
    const [timesAndHeights, setTimesAndHeights] = useState([]);
    const [dayBoundaries, setDayBoundaries] = useState([]);
    const [indices, setIndices] = useState([]);
    const indicesRef = useRef(indices);
    
    const [currentTime, setCurrentTime] = useState('12:00 am');
    const [currentHeight, setCurrentHeight] = useState('1.0 ft');
    const [arrowHeight, setArrowHeight] = useState(0);

    const [scrollOffset, setScrollOffset] = useState(0);
    const scrollOffsetRef = useRef(null);


    // Function to fetch tide data
    const fetchData = useCallback((lat,lng) => {
        console.log('TideGraph: fetchData');
        console.log('Fetching data for lat:', lat, 'lng:', lng);

        // Stormglass request
        const today = new Date();
        const startDate = new Date();
        startDate.setDate(today.getDate() - 1);
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);
        const lastDay = new Date();
        lastDay.setDate(today.getDate() + daysPerFetch);

        const url = new URL('https://api.stormglass.io/v2/tide/extremes/point');
        url.search = new URLSearchParams({
          lat: lat,
          lng: lng, 
          start: getISOTime(startDate, 0),
          end: getISOTime(lastDay, 0),
          datum: 'MLLW'
        });
      
        fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': '99b32904-c806-11ec-a8d3-0242ac130002-99b329c2-c806-11ec-a8d3-0242ac130002'
          }
        })
        .then(response => response.json())
        .then(data => {
            const formattedData = data.data.map(item => ({
                time: new Date(item.time),
                height: item.height,
                type: item.type
              }));
            
              setData(formattedData);
        })
        .catch(error => {
          console.error('Error:', error);
        });

    }, [daysPerFetch]);


    //adds tick marks to scrollContainer
    const addTickMarks = (data, canvasMeasurements) => {
        const tickMarkContainer = tickMarkContainerRef.current; 
        
        // Remove all existing tick marks
        const existingTickMarks = tickMarkContainer.querySelectorAll('.tickMark');
        existingTickMarks.forEach(tickMark => tickMark.remove());
        
        let startTime = data[0].time;
        const endTime = data[data.length - 1].time;
        const totalMinutes = (endTime - startTime) / 1000 / 60;
        const tickFrequencyInMinutes = parseInt(getCSSVariable('tickFrequencyInMinutes'), 10);
        
        // Calculate the start of the first hour after the start time
        let firstHourStartTime = new Date(startTime.getTime());
        if (firstHourStartTime.getMinutes() !== 0 || firstHourStartTime.getSeconds() !== 0 || firstHourStartTime.getMilliseconds() !== 0) {
            firstHourStartTime.setHours(firstHourStartTime.getHours() + 1);
            firstHourStartTime.setMinutes(0);
            firstHourStartTime.setSeconds(0);
            firstHourStartTime.setMilliseconds(0);
        }

        // Add a tick mark every tickFrequencyInMinutes starting from the first hour
        for (let tickMarkTime = firstHourStartTime; tickMarkTime <= endTime; tickMarkTime.setMinutes(tickMarkTime.getMinutes() + tickFrequencyInMinutes)) {
            // Calculate the x position based on the time difference between the start time and the tick mark time
            const minutesFromStart = (tickMarkTime - startTime) / 1000 / 60;
            const x = Math.round((minutesFromStart / totalMinutes) * canvasMeasurements.width);

            const tickMark = document.createElement('div');
            tickMark.className = 'tickMark';
            tickMark.style.left = `${x}px`;

            // Check if the current time falls at midnight, 3am, 6am, or 9am
            const currentHour = tickMarkTime.getHours();
            const currentMinutes = tickMarkTime.getMinutes();
            if ([0, 3, 6, 9, 12, 15, 18, 21].includes(currentHour) && currentMinutes === 0) {
                tickMark.classList.add('hourHighlightTick');
            }

            tickMarkContainer.appendChild(tickMark);
        }
    };

    // adds days to the daysContainer div
    const addDays = (timesAndHeights, canvasMeasurements) => { 

        const days = timesAndHeights
        .filter((value, index, self) => self.map(item => item.day).indexOf(value.day) === index);
    
        const dayBoundaries = days.map((day, index, array) => {
            const h2 = document.createElement('h2');
            const thisDay = day.day.replace(/\s/g, '-'); // Replace spaces with hyphens
            h2.textContent = day.day;
            h2.className = 'day ' + thisDay;
            h2.style.left = `${day.x}px`; // Set the x position
            daysContainerRef.current.appendChild(h2);
            
            const dayStartX = day.x;
            let dayEndX;
    
            if (index === array.length - 1) {
                // It's the last item
                dayEndX = canvasMeasurements.width - h2.offsetWidth;
            } else {
                // It's not the last item
                dayEndX = array[index + 1].x - h2.offsetWidth;
            }
    
            return { day: thisDay, dayStartX, dayEndX, h2 };
        });

        // make DaysContainer the width of the canvas and height of h2s for sticky .day headers
        daysContainerRef.current.style.width = `${canvasMeasurements.width}px`;
        daysContainerRef.current.style.height = `${dayBoundaries[0].h2.offsetHeight}px`;

        // set the first day as the current day
        dayBoundaries[0].h2.classList.add('current');
        dayBoundaries[0].h2.style.position = 'sticky';
        dayBoundaries[0].h2.style.left = getCSSVariable('infoLeft');
        
        return dayBoundaries;
    }

    

    // sets all the info states based on x pixel of graph to update #infoText
    const setInfo = useCallback((targetPixel) => {
        const i = Math.round(targetPixel)

        setCurrentTime(timesAndHeights[i].time);
        setCurrentHeight(timesAndHeights[i].height);
        setArrowHeight(timesAndHeights[i].arrowHeight);
    }, [timesAndHeights, setCurrentTime, setCurrentHeight, setArrowHeight]);


    const scrollContainerRef = ScrollController({ isRendered, scrollOffset, setInfo, dayBoundaries })


    // adds labels to scrollContainer
    const addLabels = useCallback((data, canvasMeasurements) => {

        const scrollContainer = scrollContainerRef.current;
        let waveCanvas = document.getElementById('wave-canvas');
        const yOffsetHigh = parseInt(getCSSVariable('labelOffsetHigh'), 10);
        const yOffsetLow = parseInt(getCSSVariable('labelOffsetLow'), 10);
        const canvasYPos = waveCanvas.offsetTop;

        // Remove existing labels
        const existingLabels = scrollContainer.querySelectorAll('h3');
        existingLabels.forEach(label => label.remove());
    
        data.slice(1, -1).forEach((item, index) => {
            const x = ((item.time - canvasMeasurements.minTime) / canvasMeasurements.timeRange) * canvasMeasurements.width;
            const yOffset = item.type === 'high' ? yOffsetHigh : yOffsetLow;
            const y = (canvasMeasurements.height - ((item.height - canvasMeasurements.minHeight) / canvasMeasurements.heightRange) * canvasMeasurements.height - yOffset) + canvasYPos;
    
            const label = document.createElement('h3');
            label.style.left = `${x}px`;
            label.style.top = `${y}px`;
            label.style.transform = 'translateX(-50%)'; // this centers the label to the point
            label.style.opacity = 0;
            label.className = `${item.type} label graphContent`;
    
        
            // Create a span for the time
            const timeSpan = document.createElement('span');
            timeSpan.className = "timeLabel";
            // Format the time as clock time with am/pm
            const time = new Date(item.time);
            const hours = time.getHours();
            const minutes = time.getMinutes();
            const ampm = hours >= 12 ? 'pm' : 'am';
            const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
            timeSpan.innerText = formattedTime;


            // Create a span for the height
            const heightSpan = document.createElement('span');
            heightSpan.className = "heightLabel";
            heightSpan.innerText = `${item.height.toFixed(1)} ft`;

            label.appendChild(timeSpan);
            label.appendChild(heightSpan);

            scrollContainer.appendChild(label);
        });
    }, [scrollContainerRef]);



    

    /* /////////////////////////////////////////////////////////////////////////////////////
    Scrolling
    */

    const scrollToTime = useCallback((targetTime) => {
        
        const scrollContainer = scrollContainerRef.current;
        
        // get scroll position for targetTime
        const startTime = new Date(canvasMeasurementsRef.current.minTime)
        const targetScroll = ((targetTime - startTime) / canvasMeasurementsRef.current.timeRange * canvasMeasurementsRef.current.width) - scrollOffsetRef.current;
        scrollContainer.scrollTo({left: targetScroll });
    }, [scrollContainerRef, canvasMeasurementsRef, scrollOffsetRef]);

    // Add key press listener to scroll forward and back
    useEffect(() => {
        const handleKeyPress = (e) => {
            const scrollContainer = scrollContainerRef.current;
            const scrollLeft = Math.round(scrollContainer.scrollLeft + scrollOffset);
        
            const highlightedHours = Array.from(scrollContainer.getElementsByClassName('hourHighlightTick'));
            const highlightedHoursPositions = highlightedHours.map(hour => Math.round(hour.offsetLeft));
        
            if (e.key === 'ArrowRight') {
                const nextHour = highlightedHoursPositions.find(position => position > scrollLeft);
        
                if (nextHour) {
                    scrollContainer.scrollTo({
                        left: nextHour - scrollOffset,
                        behavior: 'smooth'
                    });
                }
        
            } else if (e.key === 'ArrowLeft') {
                const previousHour = highlightedHoursPositions.reverse().find(position => position < scrollLeft);
        
                if (previousHour) {
                    scrollContainer.scrollTo({
                        left: previousHour - scrollOffset,
                        behavior: 'smooth'
                    });
                }
            }
        };
    
        window.addEventListener('keydown', handleKeyPress);
    
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [scrollOffset, scrollContainerRef]);

    /* /////////////////////////////////////////////////////////////////////////////////////
    Effects*/


    // add a listenener for resize and get new canvas measurments and update canvas measurments state
    // this will trigger all the necessary recalculations and redraws

    // runs once on mount to set scroll offset
    useEffect(() => {
        const arrowDiv = document.querySelector('#arrow');
        if (arrowDiv) {
            const offset = arrowDiv.getBoundingClientRect().left;
            setScrollOffset(offset);
        }
    }, []); // Empty dependency array means this effect runs once on mount

    // updates the refs for canvas measurements, data, and indices
    useEffect(() => {
        canvasMeasurementsRef.current = canvasMeasurements;
        dataRef.current = data;
        indicesRef.current = indices;
        dispatchRef.current = dispatch;
        animationRef.current = animation;
        scrollOffsetRef.current = scrollOffset
    }, [canvasMeasurements, data, indices, dispatch, animation, scrollOffset]);

    /* /////////////////////////////////////////////////////////////////////////////////////
    The Effect chain

    1.  locationPick state changes
    2.  fetchData gets new data based on location
    3.  canvas measurements calculated based on data length
    4.  indices calculated based on data and canvas measurements
    5.  times and heights calculated based on data, canvas measurements, and indices
    6.  wave, labels, and info are drawn based on indices and canvas measurements

    ///////////////////////////////////////////////////////////////////////////////////////*/


    // When locationPick changes
    // fetch data to start chart chain OR draw loading wave
    useEffect(() => {

        if(locationPick.lat !== undefined && locationPick.lng !== undefined) {
            console.log('TideGraph: Location set - ', locationPick);
            fetchData(locationPick.lat, locationPick.lng);
        }
        else {
            scrollContainerRef.current.scrollLeft = 0;
            waveCanvasRef.current.drawLoadingWave();
            scrollContainerRef.current.style.overflowX = 'hidden'; 
            setIsRendered(false);
        }
    }, [locationPick, fetchData, scrollContainerRef]); // Dependency array with location means this effect runs whenever location changes

    // when data changes
    // measure the canvas and set the canvas measurements
    useEffect(() => {
        if(data.length === 0) return;
        console.log('TideGraph: Data set ');
        const numLoads = data.length / daysPerFetch;
        const canvasMeasures = getCanvasMeasurements(data, numLoads);
        setCanvasMeasurements(canvasMeasures);

    }, [data, daysPerFetch]); // Dependency array with data means this effect runs whenever data changes

    // when canvas measurements change
    // calculate the indices
    useEffect(() => {
        if(Object.keys(canvasMeasurements).length === 0) return;
        console.log('TideGraph: Canvas measurements set');

        const newIndices = getIndices(dataRef.current, canvasMeasurements);
        setIndices(newIndices);

    }, [canvasMeasurements]);

    // when indicies change
    // calculates the times and heights
    useEffect(() => {
        if(indices.length === 0) return;
        console.log('TideGraph: Indices set');

        const newTimesAndHeights = getTimesAndHeights(dataRef.current, canvasMeasurementsRef.current, indices);
        setTimesAndHeights(newTimesAndHeights);

    }, [indices]);

    // when times and heights change
    // draw the wave, set labels, and info
    useEffect(() => {
        if(timesAndHeights.length === 0) return;
        console.log('TideGraph: timesAndHeights set');

        waveCanvasRef.current.drawChartWave(dataRef.current, indicesRef.current, canvasMeasurementsRef.current);
        scrollContainerRef.current.style.overflowX = 'auto'; 
        addTickMarks(dataRef.current, canvasMeasurementsRef.current);
        addLabels(dataRef.current, canvasMeasurementsRef.current);
        
        // adds .day h2s and returns an array of day boundaries
        const boundaries = addDays(timesAndHeights, canvasMeasurementsRef.current);
        setDayBoundaries(boundaries);

        setIsRendered(true);

    }, [timesAndHeights, setInfo, scrollContainerRef, addLabels, scrollToTime ]); 

    // when isRendered changes
    // update state machine and animate to chart
    useEffect(() => {
        if(!isRendered) return;
        console.log('TideGraph: isRendered set');

        const scrollX = scrollContainerRef.current.scrollLeft + scrollOffsetRef.current;
        setInfo(scrollX);

        // get scroll position for current time to send to animation
        const rightNow = new Date();
        const startTime = new Date(canvasMeasurementsRef.current.minTime)
        const targetScroll = ((rightNow - startTime) / canvasMeasurementsRef.current.timeRange * canvasMeasurementsRef.current.width) - scrollOffsetRef.current;

        dispatchRef.current({ type: actions.START_CHART });
        animationRef.current.loaderToChart(targetScroll);

    }, [isRendered, scrollContainerRef, scrollToTime, setInfo]);


    return (
        <>
            <div id="scrollContainer" ref={scrollContainerRef} >
                <div id="tickMarkContainer" className="graphContent" ref={tickMarkContainerRef} ></div>
                <div id="daysContainer" className="graphContent" ref={daysContainerRef}></div>
                <div id="info" className="graphContent">
                    <div id="infoText">
                        {/* <h2 className="day">{currentDay}</h2> */}
                        <h2 id="time">{currentTime}</h2>
                        <h2 id="height">{currentHeight}</h2>
                    </div>
                    <div id="arrow" className="graphContent" style={{ height: arrowHeight }} ref={arrowRef}></div>
                </div>
                <WaveCanvas ref={waveCanvasRef} state={state} />
            </div>
        </>
    )
}

export default TideGraph