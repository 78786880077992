
import { gsap } from 'gsap';
import { actions } from '../App';
import { getCSSVariable } from '../utils/Helpers';

function useAnimation(dispatch) {

  const splashToPicker = () => {
    
    document.querySelector("#btn-start").style.display = "none";
    document.querySelector("#siteTitle").style.opacity = "0";
    
    const tl = gsap.timeline();
    tl.to("#siteTitle", { 
        duration: 0.25, 
        height: 60,
        ease: "power1.in",
        onComplete: function() {
          document.querySelector("#locationPicker input").style.opacity = "0";
          document.querySelector("#siteTitle").style.height = 0;
          document.querySelector("#locationPicker input").style.display = "block";
        }
    })
    tl.to("#locationPicker input", {
        delay: 0.1,
        duration: 0.25, 
        opacity: 1
    });
    tl.call(function(){
      document.querySelector("#locationRecommendations").style.opacity = "0";
      document.querySelector("#locationRecommendations").style.display = "block";
    
    })
    tl.to("#locationRecommendations", {
      duration: 0.25,
      opacity: 1,
      onComplete: function() {
        dispatch({ type: actions.PICKER_READY });
        document.querySelector("#locationPicker input").focus();
      }
    })
  } 

  const pickerToLoader = (locationLabelHeight, waveCanvasRef) => {
    
    const tl = gsap.timeline();
   
    document.querySelector("#locationPicker").style.display = "none";
    document.querySelector("#locationRecommendations").style.display = "none";
    document.querySelector("#locationLabel h1").style.opacity = 0;
    document.querySelector("#locationLabel h2").style.opacity = 0;
    document.querySelector("#locationLabel").style.opacity = 1;
    document.querySelector("#locationLabel").style.display = 'flex';
    
    tl.to("#location", { 
        duration: .5, 
        width: '100%',
        ease: "power2.out",
    })
    tl.to("#locationLabel", {
        duration: 0.5, 
        marginTop: 30,
        marginBottom: 30,
    }, "<")
    tl.to("#locationLabel h2", {
        duration: 0.5, 
        opacity: 1,
    },"<")
    tl.to("#locationLabel h1", {
        delay: 0.25,
        duration: 0.5, 
        opacity: 1, 
        onComplete: function() {
          dispatch({ type: actions.LOADER_READY })
        }
    },"<")
    
  }
  const loaderToChart = (targetScroll) => {
    const tl = gsap.timeline();
    tl.to("#scrollContainer", {
      duration: 1,
      scrollLeft: targetScroll,
      ease: "power4.out"
    })
    tl.to(".graphContent", { 
      duration: 0.5, 
      opacity: 1,
      onComplete: function() {
        dispatch({ type: actions.CHART_READY })
      } 
    })
  }
  const chartToPicker = () => {

    const locationDivWidth = getCSSVariable('locationDivWidth');

    document.querySelectorAll(".graphContent").forEach(element => {
        element.style.opacity = 0;
    });
    const labels = document.querySelectorAll('.label');
    labels.forEach(label => label.remove());

    const days = document.querySelectorAll('.day');
    days.forEach(day => day.remove());

    document.querySelector("#locationLabel").style.opacity = "0";
    document.querySelector("#locationLabel").style.display = "none";
    document.querySelector("#locationPicker").style.opacity = "0";
    document.querySelector("#locationPicker").style.display = "flex";
    
    const tl = gsap.timeline();
    tl.to("#location", { 
        duration: .5, 
        width: locationDivWidth,
        ease: "power2.out",
    })
    tl.to("#locationPicker", {
        duration: 0.5, 
        opacity: 1
    })
    tl.call(function(){
      document.querySelector("#locationRecommendations").style.opacity = "0";
      document.querySelector("#locationRecommendations").style.display = "block";
    
    })
    tl.to("#locationRecommendations", {
      duration: 0.25,
      opacity: 1,
      onComplete: function() {
        dispatch({ type: actions.PICKER_READY });
        document.querySelector("#locationPicker input").focus();
      }
    })
  }

  return { splashToPicker, pickerToLoader, loaderToChart, chartToPicker};
}

export default useAnimation;