import React, { useEffect, useReducer } from 'react';
import './App.css';
import DataProvider from './components/DataProvider';
import AnimationContext from './components/AnimationContext';
import useAnimation from './components/useAnimation.js';
import Location from './components/Location';
import TideGraph from './components/TideGraph';

const states = {
  SPLASH: 'SPLASH',
  SPLASH_TO_PICKER: 'SPLASH_TO_PICKER',
  PICKER: 'PICKER',
  PICKER_TO_LOADER: 'PICKER_TO_LOADER',
  LOADER: 'LOADER',
  LOADER_TO_CHART: 'LOADER_TO_CHART',
  CHART: 'CHART',
  CHART_TO_PICKER: 'CHART_TO_PICKER',
};

export const actions = {
  START_PICKER: 'START_PICKER',
  PICKER_READY: 'PICKER_READY',
  START_LOADER: 'START_LOADER',
  LOADER_READY: 'LOADER_READY',
  START_CHART: 'START_CHART',
  CHART_READY: 'CHART_READY',
  BACK_TO_PICKER: 'BACK_TO_PICKER',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.START_PICKER:
      return states.SPLASH_TO_PICKER;
    case actions.PICKER_READY:
      return states.PICKER;
    case actions.START_LOADER:
      return states.PICKER_TO_LOADER;
    case actions.LOADER_READY:
      return states.LOADER;
    case actions.START_CHART:
      return states.LOADER_TO_CHART;
    case actions.CHART_READY:
      return states.CHART;
    case actions.BACK_TO_PICKER:
      return states.CHART_TO_PICKER;
    default:
      return state;
  }
};

function App() {
  const [state, dispatch] = useReducer(reducer, states.SPLASH);
  const animation = useAnimation(dispatch);

  // runs on mount
  // sets vh variable in css for mobile browsers
  useEffect(() => {
    const setVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
  
    setVh();
    window.addEventListener('resize', setVh);
  
    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);

  useEffect(() => {
    console.log(`App: ${state}`);
  }, [state]);

  return (
    <AnimationContext.Provider value={animation}>
      <DataProvider>
        <div className="App">
          <Location 
            state={state}
            dispatch={dispatch}
          />
          <TideGraph 
            state={state}
            dispatch={dispatch}
          />
        </div>
      </DataProvider>
    </AnimationContext.Provider>
  );
}

export default App;