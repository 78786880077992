import { getCSSVariable } from './Helpers';

export function getCanvasMeasurements(data, numLoads){

    const wavePanelPixels = window.innerWidth * getCSSVariable('wavePanelWidth');
    const width = Math.round(numLoads * wavePanelPixels);
    const canvasHeight = window.innerHeight * getCSSVariable('canvasPercentage');
    const bottomBuffer = canvasHeight * getCSSVariable('bottomBufferPercent');
    const height =  canvasHeight - bottomBuffer; // height for wave not canvas total
    const minHeight = Math.min(...data.map(item => item.height));
    const maxHeight = Math.max(...data.map(item => item.height));
    const heightRange = maxHeight - minHeight;
    const minTime = Math.min(...data.map(item => item.time));
    const maxTime = Math.max(...data.map(item => item.time));
    const timeRange = maxTime - minTime;

    return { width, canvasHeight, height, minHeight, maxHeight, heightRange, minTime, maxTime, timeRange };
}

export function getTimesAndHeights(data, canvasMeasurements, indices){
    const startTime = new Date(data[0].time);
    const endTime = new Date(data[data.length - 1].time);
    const totalTimeRange = endTime - startTime;

    // get distance for arrow height
    const scrollContainerHeight = document.getElementById('scrollContainer').offsetHeight;
    const waveCanvasHeight = canvasMeasurements.canvasHeight;
    const arrow = document.getElementById('arrow');
    const arrowTop = arrow.offsetTop;
    const computedStyle = window.getComputedStyle(arrow);
    const arrowMarginAmount = parseFloat(computedStyle.getPropertyValue('margin-top'));
    const gapSpace = parseInt(getCSSVariable('arrowGap'),10);

    const additionalArrowHeight = scrollContainerHeight - arrowTop - waveCanvasHeight - arrowMarginAmount - gapSpace;

    let timesAndHeights = [];

    // Loop through each pixel in the canvas
    for (let i = 0; i < canvasMeasurements.width; i++) {

        const thisPixel = i;
        // determine the day and time for this pixel
        const thisTime = new Date(startTime.getTime() + thisPixel / canvasMeasurements.width * totalTimeRange);

        // round to :00 if close (for scrolling issue on arrow clicks)
        let minutes = thisTime.getMinutes();
        if (minutes >= 59) {
            minutes = 0;
            thisTime.setHours(thisTime.getHours() + 1);
        } else if (minutes <= 1) {
            minutes = 0;
        }
        thisTime.setMinutes(minutes);
    
        // determine the height for this pixel
        let previousData = null;
        let nextData = null;
        let thisHeight = 0;
        for (let i = 0; i < data.length - 1; i++) {
            if (new Date(data[i].time) <= thisTime && new Date(data[i + 1].time) > thisTime) {
                previousData = data[i];
                nextData = data[i + 1];
                break;
            }
        }    
        if (previousData && nextData) {
            const timeRange = new Date(nextData.time) - new Date(previousData.time);
            const timeProportion = (thisTime - new Date(previousData.time)) / timeRange;
            const heightRange = nextData.height - previousData.height;
            thisHeight = previousData.height + timeProportion * heightRange;
        }

        // determine the height for the arrow
        let P0, P1, P2, P3, t;
        for (let i = 0; i < indices.length - 1; i++) {
            if (thisPixel <= indices[i].xEnd) {
                P0 = {x: indices[i].xStart, y: indices[i].yStart};
                P1 = {x: indices[i].cp1x, y: indices[i].cp1y};
                P2 = {x: indices[i].cp2x, y: indices[i].cp2y};
                P3 = {x: indices[i].xEnd, y: indices[i].yEnd};
                t = (thisPixel - P0.x) / (P3.x - P0.x);
                break;
            }
        }
        const newYPosition = Math.pow(1 - t, 3) * P0.y + 3 * Math.pow(1 - t, 2) * t * P1.y + 3 * (1 - t) * Math.pow(t, 2) * P2.y + Math.pow(t, 3) * P3.y;
        const arrowHeight = newYPosition + additionalArrowHeight;

        // push day, time, heght, and arrow height to timesAndHeights array
        timesAndHeights.push({
            x: i,
            day: formatDate(thisTime), 
            time: formatTime(thisTime),
            height: formatHeight(thisHeight),
            arrowHeight: arrowHeight    
        });
    }
    return timesAndHeights;
}

export function getIndices(data, canvasMeasurements){
  
    const indices = data.map((item, index, array) => {
        
        const xStart = ((item.time - canvasMeasurements.minTime) / canvasMeasurements.timeRange) * canvasMeasurements.width;
        const yStart = canvasMeasurements.height - ((item.height - canvasMeasurements.minHeight) / canvasMeasurements.heightRange) * canvasMeasurements.height;

        const nextTime = array[index + 1] ? array[index + 1].time : item.time;
        const xEnd = ((nextTime - canvasMeasurements.minTime) / canvasMeasurements.timeRange) * canvasMeasurements.width;

        const cp1x = xStart + (xEnd - xStart) / 2;
        const cp1y = yStart;

        const nextY = array[index + 1] ? canvasMeasurements.height - ((array[index + 1].height - canvasMeasurements.minHeight) / canvasMeasurements.heightRange) * canvasMeasurements.height : yStart;

        const cp2x = xEnd - (xEnd - xStart) / 2;
        const cp2y = nextY;

        const yEnd = nextY;

        return { xStart, yStart, cp1x, cp1y, cp2x, cp2y, xEnd, yEnd };
    });

    return indices;
}

export const formatDate = (date) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
        return 'Today';
    } else if (date.toDateString() === tomorrow.toDateString()) {
        return 'Tomorrow';
    } else if (date.toDateString() === yesterday.toDateString()) {
        return 'Yesterday';
    } else {
        const options = { 
        month: 'long', 
        day: 'numeric' 
        };
    
        const day = date.getDate();
        const suffix = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
        const ordinal = ([1, 2, 3].includes(day%10) && ![11, 12, 13].includes(day%100)) ? suffix[day%10] : suffix[0];
    
        return `${date.toLocaleDateString('en-US', options)}${ordinal}`;
    }
};

export const formatTime = (date) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    let timeString = date.toLocaleTimeString('en-US', options);
    // Convert AM/PM to lowercase
    return timeString.replace(/AM|PM/g, match => match.toLowerCase());
};

export const formatHeight = (height) => {
    return `${height.toFixed(1)} ft`;
};