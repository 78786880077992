import { useCallback, useState, useEffect, useRef } from 'react';
import { getCSSVariable } from '../utils/Helpers';

const ScrollController = ({ isRendered, scrollOffset, setInfo, dayBoundaries }) => {
    const [currentDay, setCurrentDay] = useState({});
    const currentDayRef = useRef(currentDay);
    const scrollableElementRef = useRef(null);
    

    // Handle scroll event
    const handleScroll = useCallback((e) => {
        // if (!isRendered) return;

        const thisPixel = e.target.scrollLeft + scrollOffset;
        setInfo(thisPixel); // to update info text on screen

        // find the day for this pixel
        let thisDay;
        let transition = false;
        let thisStartX;
        let thisEndX;
        let thisH2;
        let i

        for (i = 0; i < dayBoundaries.length; i++) {
            if (i === dayBoundaries.length - 1) {
                if (thisPixel > dayBoundaries[i].dayStartX) {
                    thisDay = dayBoundaries[i].day;
                    thisStartX = dayBoundaries[i].dayStartX;
                    thisEndX = dayBoundaries[i].dayEndX;
                    thisH2 = dayBoundaries[i].h2;
                    if (thisPixel > dayBoundaries[i].dayEndX) {
                        transition = true;
                    }
                    break;
                }
            } else if (thisPixel > dayBoundaries[i].dayStartX && thisPixel < dayBoundaries[i + 1].dayStartX) {
                thisDay = dayBoundaries[i].day;
                thisStartX = dayBoundaries[i].dayStartX;
                thisEndX = dayBoundaries[i].dayEndX;
                thisH2 = dayBoundaries[i].h2;
                if (thisPixel > dayBoundaries[i].dayEndX) {
                    transition = true;
                }
                break;
            }
        }

        // check if current day state needs to be updated
        if(thisDay !== currentDayRef.current.day || transition !== currentDayRef.current.transitioning) {
            if(thisDay === undefined) return;
            setCurrentDay({ day: thisDay, transitioning: transition, dayStartX: thisStartX, dayEndX: thisEndX, h2Element: thisH2, index:i});
        }
        

    }, [ scrollOffset, setInfo, dayBoundaries]);

    // adds the scroll listeners
    useEffect(() => {
        // Get the scrollable element
        const scrollableElement = scrollableElementRef.current;

        // If the scrollable element exists, attach the scroll event listener
        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', handleScroll);
            scrollableElement.addEventListener('touchmove', handleScroll); // For mobile
        }

        // Clean up function to remove the event listeners when the component unmounts
        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener('scroll', handleScroll);
                scrollableElement.removeEventListener('touchmove', handleScroll); // For mobile
            }
        };
    }, [handleScroll]);

    // fires when currentDay state changes
    // manages the sticking / unsticking of the .day h2 elements
    useEffect(() => {
        currentDayRef.current = currentDay;

        if (!currentDay.day) return;

        // in the transition zone where both h2s are unstuck
        if(currentDay.transitioning){
            let leftPos;
            const stickyDay = document.querySelector('.day.current');
            if (!stickyDay) return;

            //if scrolling forward
            if (stickyDay && stickyDay.classList.contains(currentDay.day)) { 
                // sticky day is same as currentDay
                // set left position to the endX of currentDay
                leftPos = `${currentDay.dayEndX}px`;
            }
            // else scrolling backward
            else {                          
                // sticky day is one day ahead of currentDay
                // set left position to the startX of stickyDay
                const nextDay = dayBoundaries[currentDay.index + 1];
                leftPos = `${nextDay.dayStartX}px`;

            }
            // unstick the sticky h2
            stickyDay.style.position = 'absolute'
            stickyDay.style.left = leftPos;
        }
        // currentDay should be stuck
        else {

            const oldCurrentDay = document.querySelector('.day.current');
            if (oldCurrentDay) {
                oldCurrentDay.classList.remove('current');
            }

            currentDay.h2Element.classList.add('current');
            currentDay.h2Element.style.position = 'sticky';
            currentDay.h2Element.style.left = getCSSVariable('infoLeft');

        }
    }, [currentDay, dayBoundaries]); // Add state as a dependency

        // Return the ref instead of the handleScroll function
        return scrollableElementRef;
    };

export default ScrollController;