import React, { forwardRef, useImperativeHandle, useRef  } from 'react';
import {getCSSVariable} from '../utils/Helpers';

const WaveCanvas = forwardRef(({state}, ref) => {
    const canvasRef = useRef(null);
    const frequency = 0.04;
    const amplitude = 0.03;
    let phase = 0;
    const phaseSpeed = 0.02; // Adjust this value to control the speed of the wave
   
    const topPadding = 30; // Padding at the top of the canvas in pixels so wave peaks not cut off
    const waveColor= getCSSVariable('color-blue-dark');
    const splashCanvasPercentage = getCSSVariable('splashCanvasPercentage');
    
    let requestId = null;

    const drawLoadingWave = () => {
        console.log('WaveCanvas: drawLoadingWave');

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const devicePixelRatio = window.devicePixelRatio || 1;
    
        // Set the size of the canvas to match the size of the window
        const displayWidth = window.innerWidth;
        const displayHeight = window.innerHeight * splashCanvasPercentage;
    
        // Multiply display size by the device pixel ratio to get the actual pixel size
        const canvasWidth = displayWidth * devicePixelRatio;
        const canvasHeight = displayHeight * devicePixelRatio;
    
        // Set the canvas dimensions to the actual pixel size
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        // Scale the drawing context by the device pixel ratio to ensure that
        // 1 unit in the drawing context equals 1 pixel on the canvas
        //ctx.scale(devicePixelRatio, devicePixelRatio);
    
        // Set the display size of the canvas
        canvas.style.width = `${displayWidth}px`;
        canvas.style.height = `${displayHeight}px`;

        // Draw a rectangle on the canvas
        const rectWidth = canvasWidth;
        const rectHeight = canvasHeight;
        const rectX = 0;
        const rectY = topPadding; // topPadding is so wave peaks not cut off by edge of canvas

        // make the top edge a sine wave
        ctx.beginPath();
        ctx.moveTo(rectX, rectY);
        for (let x = 0; x < rectWidth; x++) {
            const y = rectY + rectHeight * Math.sin(x * frequency) * amplitude;
            ctx.lineTo(x, y);
        }
        ctx.lineTo(rectWidth, rectY + rectHeight);
        ctx.lineTo(0, rectY + rectHeight); 
        ctx.closePath();
        ctx.fillStyle = waveColor;
        ctx.fill();
    }

    const drawChartWave = (data, indices, canvasMeasurements) => {
        console.log('WaveCanvas: drawWave');
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
    
        // size the canvas and the canvas css
        canvas.width = canvasMeasurements.width;
        canvas.height = canvasMeasurements.canvasHeight;
        canvas.style.width = `${canvas.width}px`;
        canvas.style.height = `${canvas.height}px`;

        // draw the wave
        context.fillStyle = waveColor;
        context.beginPath();
        context.moveTo(0, (canvasMeasurements.height - ((data[0].height - canvasMeasurements.minHeight) / canvasMeasurements.heightRange) * canvasMeasurements.height));

        indices.forEach((index) => {
            const { cp1x, cp1y, cp2x, cp2y, xEnd, yEnd } = index;
            context.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, xEnd, yEnd);
        });

        context.lineTo(canvas.width, canvas.height);
        context.lineTo(0, canvas.height);
        context.closePath();
        context.fill();
    }

    const animateWave = () => {
        // Update the phase
        phase += phaseSpeed;
    
        // Clear the canvas
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    
        // Redraw the wave with the updated phase
        drawLoadingWave(phase);
    
        // Request the next frame
        requestId = requestAnimationFrame(animateWave);
    };
    
    const stopAnimation = () => {
        if (requestId) {
            cancelAnimationFrame(requestId);
            requestId = null;
        }
    };
  
    useImperativeHandle(ref, () => ({

        drawLoadingWave: () => {
            drawLoadingWave();
        },

        drawChartWave: (data, indices, canvasMeasurements) => {
            drawChartWave(data, indices, canvasMeasurements);
        },

        animateWave: () => {
            animateWave();
        },
        
        stopAnimation: () => {
            stopAnimation();
        }
    }));




    // // runs on mount 
    // useEffect(() => {
    //     console.log('WaveCanvas: mount');
    // }, []);

    return (
        <canvas id="wave-canvas" ref={canvasRef}></canvas>
    );
    });

export default WaveCanvas;