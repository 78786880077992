import React, { useState } from 'react';
import DataContext from './DataContext';

const DataProvider = ({ children }) => {
    const [locationPick, setLocationPick] = useState({});
    
    return (
        <DataContext.Provider value={{ 
            locationPick, setLocationPick}}>
            {children}
        </DataContext.Provider>
    );
};

export default DataProvider;